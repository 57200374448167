import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { bgBlur } from 'src/theme/css';

import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';

import { NAV } from '../config-layout';
import SettingsButton from '../common/settings-button';
import useResponsive from 'src/hooks/useResponsive';
import LogoSec from 'src/components/Logo';
import AccountPopover from '../common/AccountPopover';
import LanguagePopover from '../common/LanguagePopover';
import { AuthUser } from 'src/@types/auth/auth';
import { Alert, Button, Typography } from '@mui/material';
import { useSelector } from 'src/redux/store';
import useLocales from 'src/hooks/useLocales';
import Iconify from 'src/components/iconify';
import axios from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------
export const isAccountCancelled = (user: AuthUser) => user?.subscription_status === 'cancelled';

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Readonly<Props>) {
  const theme = useTheme();

  const navigate = useNavigate();

  const { translate } = useLocales();

  const settings = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useSelector(state => state.auth);

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const activateMyAccount = async () => {
    try {
      if (user?.billing_provider === 'chargebee') {
        navigate(PATH_DASHBOARD.general.profile + '?tab=billing')
      } else {
        const response = await axios.get(`/subscriptions/my/portal_access`);
        const { url } = response.data;
        if (url) window.location.href = url;
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(translate('common.something_wrong'), { variant: 'error' })
    }
  }

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <LogoSec sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <LanguagePopover />

        <SettingsButton />

        <AccountPopover />
      </Stack>
    </>
  );

  const isLightMode = theme.palette.mode === 'light';
  return (
    <AppBar
      sx={{
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
        '.MuiAlert-message': {
          width: '100%'
        }
      }}
    >
      {isAccountCancelled(user) && <Alert variant='outlined' severity='warning' icon={<Iconify icon={'eva:alert-triangle-fill'} width={40} height={40} />} sx={{ pt: 2.5, pb: 2.5, alignItems: 'center', width: '-webkit-fill-available', borderRadius: 0 }}>
        <Stack
          direction={{ xs: 'column', md: 'row', lg: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column', // Change direction to column on small screens
              alignItems: 'flex-start', // Align items to the start on small screens
            },
            borderColor: 'red'
          }}>
          <Stack direction={'column'}>
            <Typography variant='subtitle1' sx={{ textAlign: 'left' }}>{translate('account_suspended.heading')}</Typography>
            <Typography variant='body2' sx={{ textAlign: 'left' }}>{translate('account_suspended.description')}</Typography>
          </Stack>
          <Button
            aria-label="close"
            sx={{
              color: isLightMode ? theme.palette.warning.darker : theme.palette.warning.light,
              borderColor: isLightMode ? theme.palette.warning.darker : theme.palette.warning.light,
              minInlineSize: 'max-content',
              mt: { xs: 1, md: 0 },
            }}
            size="small"
            variant='outlined'
            color='inherit'
            onClick={activateMyAccount}
          >
            {translate('account_suspended.activate_account_btn')}
          </Button>
        </Stack>
      </Alert>}
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
