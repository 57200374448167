// form
import { useFormContext, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps, useTheme } from '@mui/material';
import { formatNumber } from 'src/utils/formatNumber';
import { handleWheelBlur } from './RHFTextField';
// theme
import { InfoIcon } from 'src/theme/overrides/components/CustomIcons';
import { CustomTooltip } from '../tooltip';

// ----------------------------------------------------------------------

function getFormattedValue(field: ControllerRenderProps<FieldValues, string>, format: string) {
    return typeof field.value === 'number' ? formatNumber(field.value ?? 0, format) : field.value;
}


type Props = TextFieldProps & {
    name: string;
    format?: string;
    label?: string;
    labelTooltip?: string;
};

export default function RHFTextField2({ name, label, labelTooltip, helperText, format = "0.00", ...other }: Props) {
    const { control } = useFormContext();
    const theme = useTheme();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    value={getFormattedValue(field, format)}
                    onWheel={handleWheelBlur} // Attach the onWheel event handler
                    error={!!error}
                    helperText={error ? error?.message : helperText}
                    label={
                        (label || labelTooltip) &&
                        <div style={{ display: 'flex' }}>
                            <div>
                                {label}
                            </div>
                            {labelTooltip &&
                                <div style={{ display: 'inline-block', marginLeft: '4px' }}>
                                    <CustomTooltip title={labelTooltip} iconAllowed={false}>
                                        <InfoIcon sx={{ width: 19, height: 19, color: theme.palette.grey[500] }} />
                                    </CustomTooltip>
                                </div>}
                        </div>
                    }
                    {...other}
                />
            )}
        />
    );
}