import React from 'react';
import { Card, CardContent, Typography, Box, useTheme, Divider } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import { formatCurrency } from 'src/utils/formatNumber';
import Iconify from 'src/components/iconify';
import { PlanDetails } from 'src/@types/plans/plans';

interface PackageCardProps {
    isSelected: boolean;
    onSelect: () => void;
    previousPlan?: string;
    frequency: string;
    plan: PlanDetails;
}

const PackageCard: React.FC<PackageCardProps> = ({ plan, frequency, isSelected, onSelect, previousPlan = '' }) => {

    const theme = useTheme();
    const { translate, currentLang: { value: language } } = useLocales();

    const mainPrice = frequency === 'yearly' ? plan.yearly.month : plan.monthly.price;

    return (
        <Card
            sx={{
                border: isSelected ? `2px solid ${theme.palette.primary.main}` : 'none',
                cursor: 'pointer',
                position: 'relative',
                minHeight: 450,
                overflow: 'visible',
                boxShadow: theme.shadows[4]
            }}
            onClick={onSelect}
        >
            {plan.label && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: -15,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.common.black,
                        color: 'white',
                        borderRadius: 3,
                        padding: '4px 14px',
                        fontSize: '13px',
                        fontWeight: 600,
                        lineHeight: '22px'
                    }}
                >
                    {translate(plan.label)}
                </Box>
            )}
            <CardContent>
                <Typography variant="h3" lineHeight={'normal'} mb={1}>{translate(`plans.${plan.title}.title`)}</Typography>
                <Box display={'flex'}>
                    {plan.title !== 'essential' ? <>
                        <Typography variant="subtitle1" color={theme.palette.primary.main}>{formatCurrency(mainPrice, language, mainPrice % 1 === 0 ? 0 : 2, plan.currency)}{translate('plans.per_monthly')}</Typography>
                        <Typography variant='body1' color={theme.palette.grey[600]} ml={1}>({translate('plans.excluding_vat')})</Typography>
                    </> :
                        <Typography variant="subtitle1" color={theme.palette.primary.main}>{translate('plans.free')}</Typography>}

                </Box>
                {plan.title !== 'essential' && frequency === 'yearly' && <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ textDecoration: 'line-through', fontWeight: 400, color: theme.palette.grey[600], mr: 0.5 }} >{formatCurrency(plan?.yearly.off, language, plan?.yearly.off % 1 === 0 ? 0 : 2, plan?.currency ?? 'EUR')}</Typography>
                    <Typography variant='subtitle1' mr={0.5}>{formatCurrency(plan?.yearly.year, language, plan?.yearly.year % 1 === 0 ? 0 : 2, plan?.currency ?? 'EUR')}</Typography>
                    <Typography variant='body1' color={theme.palette.grey[600]}>/{translate('plans.frequency.annum')}</Typography>
                </Box>}
                <Divider sx={{ mt: 2 }} />
                <Box mt={2}>
                    {previousPlan && <Typography variant='subtitle1' mb={1}>{translate(`plans.${previousPlan}.title`)} {translate('plans.plus')}</Typography>}
                    {plan.features.map((feature, index) => (
                        <Box key={index} sx={{ display: 'flex' }}>
                            <Iconify icon={'eva:checkmark-outline'} color={theme.palette.primary.main} mr={1} />
                            <Typography variant="body2" color={theme.palette.grey[600]}>
                                {translate(`plans.features.${feature}.name`)}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};

export default PackageCard;
