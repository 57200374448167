export const plans = [
    {
        title: "legacy1",
        currency: "eur",
        monthly: {
            price: 14.95
        },
        yearly: {
            month: 9.95,
            off: 179.40,
            year: 119.40
        },
        features: [
            "invoices_credit_notes",
            "receipt_book",
            "expense_register",
            "vat_invoicing",
            "banks_sync",
            "multi_language",
            "multi_currency",
            "multi_user",
            "ioss_oss_report",
            "export_fec_accounting",
        ]
    },
    {
        title: "essential",
        currency: "eur",
        monthly: {
            price: 0
        },
        yearly: {
            month: 0,
            off: 0,
            year: 0
        },
        features: [
            "invoices_credit_notes",
            "receipt_book",
            "expense_register",
            "urssaf_report_micro_enterprises",
            "customer_files",
            "send_quotes_invoices",
            "limited_support",
        ]
    },
    {
        title: "pro",
        currency: "eur",
        monthly: {
            price: 11
        },
        yearly: {
            month: 9,
            off: 132,
            year: 108
        },
        features: [
            "auto_mandatory",
            "vat_invoicing",
            "banks_sync",
            "api_access",
            "basic_support",
            "vat_report"
        ]
    },
    {
        title: "business",
        currency: "eur",
        monthly: {
            price: 23
        },
        yearly: {
            month: 19,
            off: 276,
            year: 228
        },
        features: [
            "online_platform_connection",
            "delivery_notes",
            "online_bill_payment",
            "bank_categorize_rules",
            "margin_vat_report",
            "zapier_connection",
            "phone_support",
        ]
    },
    {
        title: "elite",
        currency: "eur",
        monthly: {
            price: 35
        },
        yearly: {
            month: 29,
            off: 420,
            year: 348
        },
        features: [
            "multi_language",
            "multi_currency",
            "multi_user",
            "ioss_oss_report",
            "export_fec_accounting",
            "priority_support",
        ]
    }
]