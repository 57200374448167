// react
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
// lodash
import { omit } from 'lodash';
// form
import { useFormContext, Controller, FieldError } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps, useTheme } from '@mui/material';
// utils
import { formatNumber } from 'src/utils/formatNumber';
// theme icon
import { InfoIcon } from 'src/theme/overrides/components/CustomIcons';
import { CustomTooltip } from '../tooltip';

// ----------------------------------------------------------------------

export const handleWheelBlur = (event: any) => {
    if (event.target.type === 'number') event?.target?.blur()
};

type IProps = {
    name: string;
    type?: string;
    format?: string;
    label?: string;
    labelTooltip?: string;
    validateOnBlur?: boolean;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({ name, format = "0.00", type = 'text', label = "", labelTooltip = "", validateOnBlur = false, ...other }: Props) {

    const theme = useTheme();

    const { control, watch, setValue, trigger, getValues, clearErrors } = useFormContext();
    const [temp, setTemp] = useState(type === 'number' ? formatNumber(watch(name) ?? 0, format) : watch(name));

    useEffect(() => {
        setTemp(type === 'number' ? formatNumber(watch(name) ?? 0, format) : watch(name))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch(name)])

    const onChangeFun = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, error: FieldError | undefined, name: string) => {
        setTemp(e.target.value);
        let { onChange } = { ...other }
        if (onChange) onChange(e);
        if (error) clearErrors(name);
    }

    const onBlurFun = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>, name: string) => {
        setTemp(e.target.type === 'number' ? formatNumber(temp ?? 0, format) : temp);
        const value: any = e.target.type === 'number' ? formatNumber(temp ?? 0, format) : temp?.trim();
        // if (value) value = value.trim();
        if (getValues(name) !== value) {
            setValue(name, value, { shouldDirty: true });
        }
        let { onBlur } = { ...other }
        if (onBlur) onBlur(e);
        if (validateOnBlur) trigger(name);
    }

    const onFocusFun = (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
        event.target.select();
        let { onFocus } = { ...other }
        if (onFocus) onFocus(event)
    }



    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { name, ref }, fieldState: { error } }) => (
                <TextField
                    fullWidth
                    name={name}
                    ref={ref}
                    type={type}
                    onChange={(e) => onChangeFun(e, error, name)}
                    onBlur={(e) => onBlurFun(e, name)}
                    onFocus={onFocusFun}
                    value={temp}
                    onWheel={handleWheelBlur} // Attach the onWheel event handler
                    label={
                        (label || labelTooltip) &&
                        <div style={{ display: 'flex' }}>
                            <div>
                                {label}
                            </div>
                            {labelTooltip &&
                                <div style={{ display: 'inline-block', marginLeft: '4px' }}>
                                    <CustomTooltip title={labelTooltip} iconAllowed={false}>
                                        <InfoIcon sx={{ width: 19, height: 19, color: theme.palette.grey[500] }} />
                                    </CustomTooltip>
                                </div>}
                        </div>
                    }
                    error={!!error}
                    helperText={error?.message}
                    {...omit({ ...other }, ['onChange', 'onBlur', 'onFocus'])}
                />
            )
            }
        />
    );
}
