import { createSlice } from '@reduxjs/toolkit';
// @types
import { dispatch } from 'src/redux/store';
// @type
import { PlansState } from 'src/@types/plans/plans';

const initialState: PlansState = { currentPlan: '', open: false, requiredFeature: '', interval: '', subscriptionStatus: '' };

const slice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        // EVENTS
        togglePlan: (state, action) => {
            state.open = !state.open;
            state.requiredFeature = action.payload.requiredFeature
            return state;
        },

        updatePlan: (state, action) => {
            state.currentPlan = action.payload.plan;
            state.interval = action.payload.interval;
            state.subscriptionStatus = action.payload.subscription_status;
            return state;
        },

        resetState: () => initialState,
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function togglePlans({ requiredFeature = '' }: { requiredFeature?: string }) {
    return async () => {
        dispatch(slice.actions.togglePlan({ requiredFeature }));
    }
}

export function updateCurrentPlan({ plan, interval, subscription_status }: { plan: string, interval: string, subscription_status: string }) {
    return async () => {
        try {
            dispatch(slice.actions.updatePlan({ plan, interval, subscription_status }));
        } catch (error) {
            console.log(error)
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}
