import { pull } from "lodash";

export function getBrowserLanguage(): any {
    return (navigator?.languages?.[0]) || navigator.language
}

// Function to move the specified feature to the top
export const moveStringToTop = (array: string[], feature: string): string[] => {
    pull(array, feature); // Remove the feature from its current position 
    array.unshift(feature); // Add the feature to the start of the array 
    return array;
};