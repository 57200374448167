import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'src/redux/store';
// @type
import { BillingInvoiceState } from 'src/@types/auth/auth';
import axios from 'src/utils/axios';

// -----------------------------------------------------------

const initialState: BillingInvoiceState = {
    data: { invoices: [] },
    error: null,
    isLoading: false,
};

const slice = createSlice({
    name: 'billing_invoices',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // EVENTS
        getInvoices: (state, action) => {
            state.isLoading = false;
            state.data.invoices = action.payload;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        resetState: () => initialState,
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBillingInvoices(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/subscriptions/my/invoices', { params, cancelToken });
            if (response.data) {
                // dispatch(slice.actions.getInvoices([
                //     { id: '1', date: new Date(), amount: '40.3', currency: 'USD' },
                //     { id: '2', date: new Date(), amount: '41.3', currency: 'USD' },
                //     { id: '3', date: new Date(), amount: '42.3', currency: 'USD' },
                //     { id: '4', date: new Date(), amount: '43.3', currency: 'USD' },
                //     { id: '5', date: new Date(), amount: '44.3', currency: 'USD' },
                //     { id: '6', date: new Date(), amount: '46.3', currency: 'USD' },
                //     { id: '7', date: new Date(), amount: '49.3', currency: 'USD' },
                // ]));
                dispatch(slice.actions.getInvoices(response.data.invoices ?? []))
                // ?? response.data.invoices ?? []
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}
