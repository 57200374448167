import React, { ReactElement } from 'react';
import { Tooltip, Button, Typography, Box, Stack, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import Iconify from '../iconify';
import useLocales from 'src/hooks/useLocales';
import { dispatch, useSelector } from 'src/redux/store';
import { togglePlans } from 'src/redux/slices/plans/plans';
import { FeatureKey, isFeatureAllowed } from 'src/utils/features';

// -------------------------------------------------------------------------------------

// Icon Types
export type ToolTipIcon = 'warning' | 'info' | 'feature';

// Custom Tooltip Component
const CustomTooltipStyled = styled(({ minWidth, disableInteractive, padding, className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} arrow disableInteractive={disableInteractive} /> // 
))(({ theme, minWidth, padding }) => ({
    [`& .MuiTooltip-tooltip`]: {
        // backgroundColor: theme.palette.background.paper,
        // color: theme.palette.text.primary,
        // boxShadow: theme.shadows[6],
        padding: padding, // theme.spacing(2),
        // borderRadius: theme.shape.borderRadius,
        minWidth: minWidth,
        marginTop: '-8px'
    },
    [`& .MuiTooltip-arrow`]: {
        width: '14px', // Increase the size of the arrow 
        height: '10px', // Increase the size of the arrow
        '&:before': {
            // backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[6],
            width: '100%',
            height: '100%',
            transform: 'rotate(45deg)',
        },
    },
    // [`&[data-popper-placement*="top"] .MuiTooltip-arrow`]: {
    //     bottom: '-2px !important',
    // },
    // [`&[data-popper-placement*="bottom"] .MuiTooltip-arrow`]: {
    //     top: '-2px !important',
    // },
    // [`&[data-popper-placement*="left"] .MuiTooltip-arrow`]: {
    //     right: '-2px !important',
    // },
    // [`&[data-popper-placement*="right"] .MuiTooltip-arrow`]: {
    //     left: '-2px !important',
    // }
}));

interface CustomTooltipCommonProps {
    title: string;
    subtitle?: string;
    action?: boolean;
    requiredFeature?: string;
    iconAllowed?: boolean;
    skipTitleCheck?: boolean;
    countCheck?: boolean;
    countAllowed?: number;
    currentCount?: number;
}
interface CustomTooltipContentProps extends CustomTooltipCommonProps {
    iconKey: string;
    iconColor: string;
}
const CustomTooltipContent = ({ subtitle, title, requiredFeature = '', action = false, iconAllowed = true, iconColor, iconKey }: CustomTooltipContentProps) => {

    const { translate } = useLocales()

    const theme = useTheme();

    return (
        <Box>
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    borderBottom: action ? `solid 1px ${theme.palette.divider}` : 'none',
                    pb: action ? 2 : 0, // Add padding below the content
                }}
                spacing={2} // Space between the icon and text
            >
                {iconAllowed && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={iconKey} fontSize="large" color={iconColor} width={30} height={30} />
                </Box>}
                <Box>
                    <Typography variant={subtitle ? "subtitle2" : "body2"} sx={{ fontWeight: subtitle ? 'bold' : 'normal', mb: subtitle ? 0.5 : 0 }}>
                        {title}
                    </Typography>
                    {subtitle && <Typography variant="body2" color="text.secondary">
                        {subtitle}
                    </Typography>}
                </Box>
            </Stack>
            {action &&
                <Button
                    variant="contained"
                    color="warning"
                    fullWidth
                    sx={{ textTransform: 'none', fontWeight: 'bold', mt: 2, boxShadow: theme.shadows[4], color: theme.palette.background.paper }}
                    onClick={() => dispatch(togglePlans({ requiredFeature }))}
                >
                    {translate('upgrade.upgrade_now_btn')}
                </Button>}
        </Box>

    )
};

interface CustomTooltipProps extends CustomTooltipCommonProps {
    children: ReactElement<{ disabled?: boolean }>;
    icon?: ToolTipIcon;
    placement?: 'top-start' | 'left' | 'right' | 'top' | 'bottom';
};
export const CustomTooltip = ({ children, title, requiredFeature = '', countCheck = false, countAllowed = 0, currentCount = 0, subtitle = '', skipTitleCheck = false, action = false, iconAllowed = true, icon = 'feature', placement = 'bottom' }: CustomTooltipProps) => {
    const theme = useTheme();

    const { translate } = useLocales();

    const { currentPlan, subscriptionStatus } = useSelector(state => state.plans);

    // Simulated user plan check
    let isPremiumUser = !title // true; // Change to true for premium users

    let iconKey = icon === 'warning' ? 'eva:alert-triangle-outline' : icon === 'info' ? 'eva:info-outline' : 'eva:arrow-circle-up-outline';
    let iconColor = icon === 'warning' ? theme.palette.warning.main : icon === 'info' ? theme.palette.info.main : theme.palette.primary.main;

    if (requiredFeature && (!title || skipTitleCheck)) { // 
        let featureAllowed = isFeatureAllowed({ feature: requiredFeature as FeatureKey, currentPlan, subscriptionStatus });
        if (countCheck && currentCount && countAllowed && currentCount >= countAllowed) {
            featureAllowed = false;
        }
        action = !featureAllowed;
        title = translate(`plans.features.${requiredFeature}.btn_title`);
        // title = !title ? translate(`plans.features.${requiredFeature}.btn_title`) : title;
        subtitle = translate(`plans.features.${requiredFeature}.btn_subtitle`);
        // subtitle = !subtitle ? translate(`plans.features.${requiredFeature}.btn_subtitle`) : subtitle;
        isPremiumUser = featureAllowed;
        iconKey = 'eva:arrow-circle-up-outline';
        iconColor = theme.palette.warning.main;
        iconAllowed = true;
        // Add `disabled` prop to children if applicable
        children = !featureAllowed
            ? React.cloneElement(children, {
                disabled: !featureAllowed, // Adds `disabled` dynamically
            })
            : children;
    }


    return (
        <CustomTooltipStyled
            title={!isPremiumUser ? <CustomTooltipContent title={title} subtitle={subtitle} action={action} requiredFeature={requiredFeature} iconKey={iconKey} iconColor={iconColor} iconAllowed={iconAllowed} /> : ''}
            arrow
            disableHoverListener={isPremiumUser} // Tooltip only works for non-premium users
            disableFocusListener={isPremiumUser}
            disableTouchListener={isPremiumUser}
            minWidth={iconAllowed ? 250 : 'auto'}
            padding={action ? theme.spacing(2) : theme.spacing(1)}
            placement={placement}
            disableInteractive={!action}
        // open={true}
        >
            <span style={{ width: 'fit-content' }}>
                {children}
            </span>
        </CustomTooltipStyled>
    );
};
