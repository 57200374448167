import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import reportUrsaafReducer from './slices/reports/urssaf';
import transactionReducer from './slices/dashboard/transactions';
import yearlyRevenueReportReducer from './slices/dashboard/yearly_revenue_report';
import monthlyRevenueReportReducer from './slices/dashboard/monthly_revenue_report';
import microRevenueReportReducer from './slices/dashboard/micro_revenue_report';
import companyReducer from './slices/company/company';
import teamReducer from './slices/team/team';
import plansReducer from './slices/plans/plans';
import authReducer from './slices/auth/auth';
import maintenanceReducer from './slices/maintenance/maintenance';
import notificationReducer from './slices/notifications/notifications';
import invoicesReducer from './slices/invoice/invoices';
import activitiesReducer from './slices/activities/activities';
import errorReducer from './slices/error/error';
import paymentsReducer from './slices/payments/payments';
import articlesReducer from './slices/articles/articles';
import clientsReducer from './slices/client/clients';
import countriesReducer from './slices/countries/countries';
import currenciesReducer from './slices/currencies/currencies';
import varRatesReducer from './slices/vat/vat';
import applicableTaxesReducer from './slices/vat/applicable_taxes';
import quotesReducer from './slices/quotes/quotes';
import suppliersReducer from './slices/supplier/suppliers';
import expensesReducer from './slices/expenses/expenses';
import recurringReducer from './slices/recurring/recurring';
import creditNotesReducer from './slices/credit_notes/credit_notes';
import documentsReducer from './slices/documents/documents';
import expenseTypesReducer from './slices/expenses/expense_types';
import recurringExpenseReducer from './slices/recurring/recurring_expense';
import banksReducer from './slices/banks/banks';
import bankTransactionsReducer from './slices/banks/transactions';
import bankRulesReducer from './slices/banks_rules/banks_rules';
import appNotificationReducer from './slices/notifications/app_notifications';
import appIntegrationsReducer from './slices/integrations/app_integrations';
import deliveryNotesReducer from './slices/delivery_notes/delivery_notes';
import accountingConfigurationReducer from './slices/account_configuration/account_configuration';
import billingInvoicesReducer from './slices/billing_invoices/billing_invoices';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const rootReducer = combineReducers({
    activities: activitiesReducer,
    accounting_configuration: accountingConfigurationReducer,
    applicable_taxes: applicableTaxesReducer,
    app_integrations: appIntegrationsReducer,
    app_notifications: appNotificationReducer,
    articles: articlesReducer,
    auth: authReducer,
    bank_rules: bankRulesReducer,
    bank_transactions: bankTransactionsReducer,
    banks: banksReducer,
    billing_invoices: billingInvoicesReducer,
    clients: clientsReducer,
    company: companyReducer,
    countries: countriesReducer,
    credit_notes: creditNotesReducer,
    currencies: currenciesReducer,
    delivery_notes: deliveryNotesReducer,
    documents: documentsReducer,
    error: errorReducer,
    expenses: expensesReducer,
    expense_types: expenseTypesReducer,
    invoices: invoicesReducer,
    maintenance: maintenanceReducer,
    micro_reports: microRevenueReportReducer,
    monthly_revenue_report: monthlyRevenueReportReducer,
    notifications: notificationReducer,
    payments: paymentsReducer,
    quotes: quotesReducer,
    recurring: recurringReducer,
    recurring_expense: recurringExpenseReducer,
    suppliers: suppliersReducer,
    transactions: transactionReducer,
    report_urssaf: reportUrsaafReducer,
    vat_rates: varRatesReducer,
    yearly_revenue_report: yearlyRevenueReportReducer,
    team: teamReducer,
    plans: plansReducer
});

export { rootPersistConfig, rootReducer };
