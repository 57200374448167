import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function tooltip(theme: Theme) {
  // const lightMode = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.background.paper, // theme.palette.grey[lightMode ? 800 : 700],
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[6],
          padding: theme.spacing(1),
          fontSize: 14,
          fontWeight: 400
        },
        arrow: {
          color: theme.palette.background.paper, //theme.palette.grey[lightMode ? 800 : 700],
        },
      },
    },
  };
}
