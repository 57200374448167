import { Box } from '@mui/material';
import PackageCard from './PackageCard'; // Import the PackageCard component
import { PlanDetails } from 'src/@types/plans/plans';

// ---------------------------------------------------------------------


interface PackageSelectionProps {
    allPackages: PlanDetails[];
    selectedPackage: string;
    changePlanFun: (plan: string) => void;
    frequency: string;
}
const PackageSelection = ({ allPackages, selectedPackage, changePlanFun, frequency }: PackageSelectionProps) => (
    <Box sx={{ mt: 1 }}>
        <Box sx={{ display: 'flex', overflowX: 'auto', gap: 2, p: 2 }}>
            {allPackages.map((pkg, index) => (
                <Box key={index} sx={{ width: 260, flex: '0 0 auto', pt: 2 }}>
                    <PackageCard
                        plan={pkg}
                        isSelected={selectedPackage === pkg.title}
                        onSelect={() => changePlanFun(pkg.title ?? '')}
                        previousPlan={index > 0 && allPackages[index].title !== 'essential' && !allPackages[index].title.includes('legacy') ? allPackages[index - 1].title : ''}
                        frequency={frequency}
                    />
                </Box>
            ))}
        </Box>

    </Box>
);

export default PackageSelection;
