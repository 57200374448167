export const allPlans = ['essential', 'pro', 'business', 'elite', 'legacy1'] as const;

export type PlanNameKey = (typeof allPlans)[number];

export const features = {
    invoices_credit_notes: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    quotes: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    payments: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    down_payment_invoices: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    customer_files: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    item_catalog: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    customize_documents: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    receipt_book: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    expense_register: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    supplier_file: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    csv_export: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    urssaf_report_micro_enterprises: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    activity_report: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    email_support: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    chat_support: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    multi_activity_management: ['pro', 'business', 'elite', 'legacy1'],
    send_quotes_invoices: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    vat_invoicing: ['pro', 'business', 'elite', 'legacy1'],
    auto_mandatory: ['pro', 'business', 'elite', 'legacy1'],
    import_invoices: ['pro', 'business', 'elite', 'legacy1'],
    import_articles: ['pro', 'business', 'elite', 'legacy1'],
    import_customer: ['pro', 'business', 'elite', 'legacy1'],
    payment_certificates: ['pro', 'business', 'elite', 'legacy1'],
    recurring_invoices: ['pro', 'business', 'elite', 'legacy1'],
    acknowledgement_receipts: ['pro', 'business', 'elite', 'legacy1'],
    delivery_notes: ['business', 'elite', 'legacy1'],
    attachments: ['pro', 'business', 'elite', 'legacy1'],
    multi_language: ['elite', 'legacy1'],
    multi_currency: ['elite', 'legacy1'],
    online_bill_payment: ['business', 'elite', 'legacy1'],
    import_receipts: ['pro', 'business', 'elite', 'legacy1'],
    import_expenses: ['pro', 'business', 'elite', 'legacy1'],
    receipts_storage: ['pro', 'business', 'elite', 'legacy1'],
    recurring_expenses: ['pro', 'business', 'elite', 'legacy1'],
    expenditure_categories: ['essential', 'pro', 'business', 'elite', 'legacy1'],
    banks_sync: ['pro', 'business', 'elite', 'legacy1'],
    bank_categorize_rules: ['business', 'elite', 'legacy1'],
    multi_user: ['elite', 'legacy1'],
    vat_report: ['pro', 'business', 'elite', 'legacy1'],
    margin_vat_report: ['business', 'elite', 'legacy1'],
    ioss_oss_report: ['elite', 'legacy1'],
    export_fec_accounting: ['elite', 'legacy1'],
    api_access: ['pro', 'business', 'elite', 'legacy1'],
    online_platform_connection: ['business', 'elite', 'legacy1'],
    zapier_connection: ['business', 'elite', 'legacy1'],
    sync_products: ['elite', 'legacy1'],
    auto_vat: ['elite', 'legacy1'],
    sync_pay_commission: ['elite', 'legacy1'],
    phone_support: ['business', 'elite', 'legacy1'],
    priority_support: ['elite', 'legacy1'],
} as const;

export type FeatureKey = keyof typeof features;


export const isFeatureAllowed = ({ feature, currentPlan, subscriptionStatus }: { feature: FeatureKey, currentPlan: string, subscriptionStatus: string }) => subscriptionStatus === 'trial' ? true : (features[feature] as readonly PlanNameKey[]).includes(currentPlan as PlanNameKey)

export const currentPlanSequence = ({ currentPlan }: { currentPlan: PlanNameKey }) => allPlans.indexOf(currentPlan);

export const getMinimumPlanForFeature = (feature: FeatureKey): PlanNameKey | null => {
    const supportedPlans = [...features[feature]];

    if (!supportedPlans || (supportedPlans.length as number) === 0) {
        return null; // No plan supports this feature
    }

    // Find the minimum plan based on order in allPlans
    return supportedPlans.reduce((minPlan, currentPlan) =>
        allPlans.indexOf(currentPlan) < allPlans.indexOf(minPlan) ? currentPlan : minPlan
    );
};


