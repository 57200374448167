// react
import { useEffect } from 'react';
// react router
import { useLocation } from 'react-router';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { fr, enUS } from 'date-fns/locale'; // locale
// intercom
import { IntercomProvider } from 'react-use-intercom';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SnackbarProvider } from './components/snackbar';
import Version from './Version';
import AuthProvider from './contexts/AuthProvider';
import MaintenanceGuard from './guards/MaintenanceGuard';
import OnboardingGuard from './guards/OnboardingGuard';
import ErrorGuard from './guards/ErrorGuard';
import Notifications from './components/Notifications';
import { SettingsDrawer, useSettingsContext } from './components/settings';
import { ErrorBoundary } from './pages/error/ErrorBoundary';
// hooks
import useLocales from './hooks/useLocales';
import AccountPlanDialog from './sections/plans/AccountPlan';


// ----------------------------------------------------------------------

export default function App() {
  const { currentLang: { value: language } } = useLocales();

  const { pageKey } = useSettingsContext();

  const location = useLocation(); // Get current route location

  // switch off logs in production
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.log = () => { };
    }
  }, []);

  const isPublicPath = location.pathname.indexOf('/public') === -1;

  return (
    <IntercomProvider appId='ls1z7xy7' shouldInitialize={isPublicPath} autoBoot>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={language === 'fr' ? fr : enUS}>
        <MotionLazy>
          <MaintenanceGuard>
            <OnboardingGuard>
              <ThemeProvider>
                <SettingsDrawer />
                <ErrorBoundary>
                  <AuthProvider />
                  <SnackbarProvider>
                    <ErrorGuard>
                      <AccountPlanDialog />
                      <ProgressBarStyle />
                      {/* Conditionally render Notifications based on route path */}
                      {isPublicPath && <Notifications />}
                      <ScrollToTop />
                      <Router key={pageKey} />
                      <Version />
                    </ErrorGuard>
                  </SnackbarProvider>
                </ErrorBoundary>
              </ThemeProvider>
            </OnboardingGuard>
          </MaintenanceGuard>
        </MotionLazy>
      </LocalizationProvider>
    </IntercomProvider>
  );
}
